.ck-editor {
    width: 100% !important;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

    .ck-editor__main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

        .ck-editor__editable_inline {
            min-height: 200px;
            flex-grow: 1;
        }

.ck.ck-balloon-panel {
    z-index: 10001 !important;
}

.evidencelink {
    /*padding: 4px 8px;
    margin-bottom: 4px;
    margin-right: 4px;
    background: #467fd7;
    border-radius: 2px;
    border-color: #d0d0d0;
    color: white;
    cursor: pointer;*/
}

    .evidencelink::selection, .documentlink::selection, .placeholder::selection {
        display: none;
    }

.mention-chip {
    background: #cccccc;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
}
